<template>
  <div>
    <ViewTemplateReportList :panel="panel" :reports="reports" />
  </div>
</template>

<script>
import ViewTemplateReportList from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportList";

export default {
  name: "RelatorioStockView",
  components: { ViewTemplateReportList },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Relatório",
        showFilter: false,
        showSearch: true,
        showButtons: false,
      },
      reports: {
        default: [
          {
            name: "Produtos",
            description: "Filtre os produtos, reservados, locados, disponíveis e outros critérios...",
            routeName: "reportStockProduct",
          },
          {
            name: "Produtos por Cliente",
            description: "Filtre os clientes e identifique quais equipamentos estão em sua posse...",
            routeName: "reportStockProductByCustomer",
          },
          {
            name: "Patrimônios",
            description: "Filtre os ativos por status e identifique em quais clientes estão...",
            routeName: "reportStockPatrimony",
          },
          {
            name: "Histórico de Patrimônios",
            description: "Filtre os ativos e identifique por quais locações passaram...",
            routeName: "reportStockHistoryPatrimony",
          },
        ],
        saved: [],
      },
    };
  },
};
</script>
